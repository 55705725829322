import axios from "@axios";
import ax from "axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        ax.get("/chat/sessions")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getProfileUser() {
      return {
        data: {
          avatar: "https://randomuser.me/api/portraits/men/11.jpg",
        },
      };
      return new Promise((resolve, reject) => {
        const data = {
          data: {
            avatar: "https://randomuser.me/api/portraits/men/11.jpg",
          },
        };
        resolve(data);
        /* axios
          .get("/apps/chat/users/profile-user")
          .then((response) => resolve(response))
          .catch((error) => reject(error)); */
      });
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("sessionId", userId);
        ax.post("/chat/allMessages/", formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        /* axios
          .get(`/apps/chat/chats/${userId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error)); */
      });
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        /* axios
          .post(`/apps/chat/chats/${contactId}`, { message, senderId })
          .then((response) => resolve(response))
          .catch((error) => reject(error)); */
        const formData = new FormData();
        formData.append("messageType", "text");
        formData.append("chatType", "private");
        formData.append("messageBody", message);
        formData.append("receiverId", contactId);
        ax.post("/chat/messages", formData)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    },
  },
};
