<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar
      size="42"
      :src="thisUser.profileImage"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ thisUser.username }}
      </h5>
      <p class="card-text text-truncate">
        {{ $t(`chat.${thisUser.userType}`) }}
      </p>
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time">{{
        moment(user.updatedAt).locale($i18n.locale).format("MMM d")
      }}</small>
      <!-- <b-badge v-if="unreadMessages" pill variant="primary" class="float-right">
        {{ unreadMessages }}
      </b-badge> -->
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import useChat from "./useChat";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  methods: {
    moment: function (input) {
      return moment(input);
    },
  },

  setup(props) {
    const { resolveAvatarBadgeVariant } = useChat();

    const thisUser =
      store.getters["auth/user"]._id == props.user.receiverId._id
        ? props.user.senderId
        : props.user.receiverId;
    const unreadMessages =
      store.getters["auth/user"]._id == props.user.receiverId._id
        ? props.user.receiverUnread
        : props.user.senderUnread;

    return { formatDateToMonthShort, resolveAvatarBadgeVariant, thisUser, unreadMessages };
  },
};
</script>

<style></style>
