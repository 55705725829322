<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          shallShowUserProfileSidebar =
            false
      "
    />
    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">
          {{ $t("chat.init") }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
              />
              <div>
                <h6 class="mb-0">
                  {{ activeChat.contact.fullName }}
                </h6>
                <div class="text-muted">{{ $t(`chat.${activeChat.contact.userType}`) }}</div>
              </div>
            </div>
          </header>
        </div>
        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserData.data.profileImage"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input v-model="chatInputMessage" :placeholder="$t('chat.enterMessage')" />
          </b-input-group>
          <b-button variant="primary" type="submit"> {{ $t("chat.send") }} </b-button>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.sessionId : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from "@/store";
import { io } from "socket.io-client";
import { ref, onUnmounted, nextTick } from "@vue/composition-api";
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from "@themeConfig";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import chatStoreModule from "./chatStoreModule";
import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue";
import ChatLog from "./ChatLog.vue";
import useChat from "./useChat";
import { mapGetters } from "vuex";
export default {
  components: {
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },

  methods: {
    getSocket: function () {
      return this.socket;
    },
  },

  setup() {
    const CHAT_APP_STORE_MODULE_NAME = "app-chat";

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
      store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    });

    const { resolveAvatarBadgeVariant } = useChat();

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    };

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([]);

    const fetchChatAndContacts = () => {
      store.dispatch("app-chat/fetchChatsAndContacts").then((response) => {
        chatsContacts.value = response.data.data;
      });
    };

    fetchChatAndContacts();

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({});
    const chatInputMessage = ref("");
    const openChatOfContact = (userId) => {
      // Reset send message input value
      chatInputMessage.value = "";
      const currentUserId = store.getters["auth/user"]._id;

      store.dispatch("app-chat/getChat", { userId }).then((response) => {
        //activeChat.value = response.data;

        const contactObj =
          response.data[currentUserId == response.data.receiverId._id ? "senderId" : "receiverId"];

        activeChat.value = {
          contact: {
            id: contactObj._id,
            avatar: contactObj.profileImage || "",
            fullName: contactObj.username,
            userType: contactObj.userType,
          },
          sessionId: userId,
          messages: response.data.messages || [],
        };

        // Set unseenMsgs to 0
        /* const contact = chatsContacts.value.find((c) => c.id === userId);
        if (contact) {
          contact.chat.senderUnread = 0;
          contact.chat.receiverUnread = 0;
        } */

        /* chatsContacts.value[0].senderUnread = 0;
        chatsContacts.value[0].receiverUnread = 0; */

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog();
        });
      });

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false;
    };

    const socket = io("wss://plankton-app-9npsd.ondigitalocean.app/");
    //const socket = io("ws://localhost:3000/");
    socket.on("message", (msg) => {
      /* console.log(activeChat.value);
      console.log(msg);
 */
      const { action, payload } = msg;

      if (action && payload) {
        switch (action) {
          case "receive_message":
            activeChat.value.messages.push(payload);
            nextTick(() => {
              scrollToBottomInChatLog();
            });
          default:
            break;
        }
      }
    });

    const sendMessage = () => {
      if (!chatInputMessage.value) return;

      const wsPayload = {
        action: "send_message",
        payload: {
          messageType: "text",
          chatType: "private",
          messageBody: chatInputMessage.value,
          metadata: chatInputMessage.value,
          receiverId: activeChat.value.contact.id,
        },
        token: localStorage.getItem("accessToken"),
      };

      socket.emit("message", wsPayload);

      chatInputMessage.value = "";
      nextTick(() => {
        scrollToBottomInChatLog();
      });
    };

    const sendMessage2 = () => {
      if (!chatInputMessage.value) return;
      const payload = {
        contactId: activeChat.value.contact.id,
        senderId: profileUserData.value.data._id,
        message: chatInputMessage.value,
      };

      store.dispatch("app-chat/sendMessage", payload).then((response) => {
        const { data } = response;

        activeChat.value.messages.push(data.data);

        chatInputMessage.value = "";

        nextTick(() => {
          scrollToBottomInChatLog();
        });
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({});
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({});

    const shallShowUserProfileSidebar = ref(false);
    const showUserProfileSidebar = () => {
      /* store.dispatch("app-chat/getProfileUser").then((response) => {
        profileUserData.value = response.data;
        shallShowUserProfileSidebar.value = true;
      }); */
    };

    profileUserData.value = {
      data: store.getters["auth/user"],
    };

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false);

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true;
      }
    };

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    };
  },

  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "sticky" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
};
</script>
<style scoped>
.chat-application .content-wrapper {
  padding-bottom: 0px !important;
}
</style>
<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
