import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=044549dc&scoped=true&"
import script from "./Chat.vue?vue&type=script&lang=js&"
export * from "./Chat.vue?vue&type=script&lang=js&"
import style0 from "./Chat.vue?vue&type=style&index=0&id=044549dc&prod&scoped=true&lang=css&"
import style2 from "./Chat.vue?vue&type=style&index=2&id=044549dc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044549dc",
  null
  
)

export default component.exports